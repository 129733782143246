<template>
  <div class="talk-skill-edit-page">
    <van-nav-bar
      v-if="!$isMobile"
      fixed
      placeholder
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      z-index="1000"
      @click-left="$router.back()"
    />
    <van-form
      scroll-to-error
      style="border-radius: 8px;"
      input-align="right"
      error-message-align="right"
      @submit="onSubmit"
    >
      <van-cell
        icon="info-o"
        style="padding-bottom: 0;"
        :title="$t('可以对文字话术进行修改，发送后不影响原有话术')"
        required
        :border="false"
      />
      <van-field
        v-model="item.content"
        input-align="left"
        :placeholder="$t('请输入')"
        :required="false"
        rows="3"
        autosize
        type="textarea"
        maxlength="1000"
        show-word-limit
        :rules="[{ required: true }]"
      />
      <div class="actions">
        <van-button
          type="info"
          round
          native-type="submit"
          color="#EED484"
          @click="sendMessage"
        >
          {{ $t("发送") }}
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { speechCraftDetail } from '@/services/talk-skill.js'
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading.js'
import wxSDK from '@/scripts/wxsdk'
export default {
  data() {
    return {
      item: {
        content: '',
      },
    }
  },
  async created() {
    const res = await speechCraftDetail({ id: this.$route.query.id })
    this.item.content = res.content
  },
  methods: {
    async sendMessage() {
      loading.showLoading()
      try {
        await wxSDK.sendChatMessage('text', {
          content: this.item.content,
        })
        this.$router.back()
      } catch (error) {}
      loading.hideLoading()
    },
  },
}
</script>
<style lang="less" scoped>
.talk-skill-edit-page {
  padding: 10px 16px;
  .actions {
    background: white;
    position: sticky;
    bottom: 0;
    padding: 16px 8px;
    display: flex;
    justify-content: space-around;
    button {
      color: #0d171a !important;
      flex: 1;
      margin: 0 4px;
    }
  }
}
</style>
